import { useEffect } from 'react'
import { Button, SvgIcon } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { ReactComponent as WhastAppIcon} from '../images/whatsapp-brands.svg';
import { useLocation } from 'react-router-dom';

export default function ThankYou() {
  const location = useLocation();

  useEffect(() => {
    location?.state?.google && window.gtag('event', 'conversion', {'send_to': 'AW-392391935/xy9ZCK-P9ZICEP_ZjbsB'});
  }, [location])

  return (
    <div className="App">
      <h2 className="titles"> Falta pouco para finalizar a sua inscrição </h2>
      <h4 className="text"> Assista esse vídeo para entender como vai acontecer o evento </h4>
      <div>
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            controls={false}
            light={true}
            url="https://youtu.be/Xu0El4PXya4"
            width="90%"
            height="90%"
          />
        </div>
      </div>
      <h4 className="text">
        Conecte comigo através do WhatAspp para receber todas as informações
        e links importantes relacionado ao evento
      </h4>
      <Button
        variant="contained"
        style={{ backgroundColor: '#25D366', color: 'white'  }}
        size="large"
        href="https://chat.whatsapp.com/FKNHMhhuL92DvU6jmVEVcX"
      >
        <SvgIcon component={WhastAppIcon} viewBox="0 0 600 476.6"/>
        Entrar no Grupo de WhatsApp
      </Button>
    </div>
  )
}
