export async function post(url, data, optionalHeader = {}) {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: optionalHeader,
  });
  if (!response.ok) {
    const error = await response.json();
    throw error;
  }
  const dataRes = await response.json();
  return dataRes;
};

export async function get(url, optionalHeader = {}) {
  const response = await fetch(url, {
    headers: optionalHeader,
  });
  if (!response.ok) {
    const data = await response.json();
    throw data;
  }
  const data = await response.json();
  return data;
};
