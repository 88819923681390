import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import App from './App';
import ThankYou from './components/ThankYou';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GA_TRACKING_CODE, {
      page_path: location.pathname + location.search,
    });
  }, [location]);
}

function Routes() {
  usePageViews();
  return <Switch>
    <Route
      exact
      path="/obrigado"
      render={(props) => <ThankYou {...props} />}
    />
    <Route
      exact
      path="/thankyou"
      render={(props) => <ThankYou {...props} />}
    />
    <Route
      exact
      path="*"
      render={(props) => <App {...props} />}
    />
  </Switch>
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
