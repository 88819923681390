import './App.css';
import ReactPlayer from 'react-player';
import Form from './components/Form'
import { Avatar, Button } from '@material-ui/core';
import aecio from './images/aecio.png';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff8303',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    type: 'dark',
  },
});

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="first-section">
          <div>
            <h2 className="headline">
              Encontro da Masterclass Vaqueiro ganhador de prêmios
            </h2>
            <h5 className="text">
              A Masterclass vaqueiro ganhador de prêmios será uma
              live online e gratuita, onde eu ensino os primeiros passos
              de como você pode correr de forma consistente,
              se apresentar bem e ganhar prêmios.
            </h5>
            <h4 className="headline">
              Eu vou disponibilizar esse conteúdo no dia 17 de maio, às 20:00.
            </h4>
          </div>
          <div>
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                controls={false}
                light={true}
                url="https://youtu.be/TsIFH2zHU-Y"
                width="90%"
                height="90%"
              />
            </div>
          </div>
        </div>
        <section id="register" className="form-section">
          <Form />
        </section>
        <section>
          <h2 className="titles"> Lives no Instagram </h2>
          <h5 className="text">
            Vai ter 1 live no Instagram, onde eu vou dar uma aula específica
            ou entrevistar vaqueiros que passaram a correr melhor para entender
            o que funcionou para eles conseguirem melhores resultados.
        </h5>
          <h4 className="headline">
            Esta live irá ocorrer nos dia 13 de maio, às 20:00.
        </h4>
        </section>
        <section className="about-section">
          <div className="flex-container">
            <Avatar alt="Aecio Levy" src={aecio} className={classes.large} />
          </div>
          <h2 className="titles"> Quem é Aécio Levy? </h2>
          <h5 className="text">
            Aécio Levy é apaixonado por vaquejada. Sua especialidade é no desenvolvimento das
            habilidades de vaqueiros para terem melhores desempenhos na vaquejada, pois acredita
            que todos vaqueiros podem evoluir, tendo a mentalidade certa e o caminho correto de treino.
            Seu maior objetivo é ajudar vaqueiros a correrem de forma consistente,
            se apresentarem bem e ganharem prêmios.
        </h5>
          <div className="button-cta">
          <Button  style={{ padding: '0 5%'}} color="primary" variant="contained" href="#register">
            Quero me inscrever gratuitamente no evento Vaqueiro Ganhador De Prêmios
          </Button>
        </div>
        </section>
      </div>
    </ThemeProvider>

  );
}

export default App;
