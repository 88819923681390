import { useState, useRef, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addLead } from '../api/addLead';
import { getFunnel } from '../lib/getFunnel';
import { useHistory, useLocation } from 'react-router-dom';
import { to } from 'to-await';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > button': {
      margin: theme.spacing(2),
      width: '25ch',
    },
  },
}));

const initialState = {
  agreement: false,
  name: {
    error: false,
    value: '',
    helperText: 'Nome não é válido. Escreva um nome com mais de 2 letras.'
  },
  email: {
    error: false,
    value: '',
    helperText: 'Email não é válido. Use esse formato celso@vitorio.com'
  },
  phone: {
    error: false,
    value: '',
    helperText: 'Telefone não é válido. Use esse formato (81) 91000 0000'
  }
}

export default function Form () {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const firstRender = useRef(true);
  const [formState, setFormState] = useState(initialState);
  const [registerDisable, setRegisterDisabled] = useState(true);


  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    function formIsValid() {
      let isValid = (!formState.name.error
        && !formState.phone.error
        && formState.agreement && !formState.email.error
      );
      return isValid;
    }

    setRegisterDisabled(!formIsValid());
  }, [formState]);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let test = !re.test(String(email).toLowerCase());
    return test;
  }

  function validateName(name) {
    return name.length < 3;
  }

  function validatePhone(phone) {
    return !/\(\d{2}\) \d{5} \d{4}/.test(phone);
  }

  function onKeyUp (event) {
    const { name, value } = event.target;
    const formatted = value.length > 1 ? value.replace(/\D/g, '').replace(/^(\d{2})(\d{1,5})?(\d{4})?/, function replacePhone(fullMatch, first, second, third) {
      return `(${first ? first : ''}) ${second ? `${second}`: ''} ${third ? third : ''}`;
    }) : value;
    setFormState({ ...formState, [name]: { ...formState[name], value: formatted }});
  }

  function validate(name, value) {
    switch (name) {
    case 'name':
      return  validateName(value);
    case 'email':
      return validateEmail(value);
    case 'phone':
      return validatePhone(value);
    default:
      return false;
    }
  }


  function handleChangeCheckbox(event) {
    setFormState({ ...formState, [event.target.name]: event.target.checked });
  }

  function onBlur(event) {
    const {name, value} = event.target;
    const error = validate(name, value);
    setFormState({...formState, [name]: { ...formState[name], error }})
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormState({...formState, [name]: { ...formState[name], error: false, value }});
  }

  async function handleRegistration() {
    setRegisterDisabled(true);
    const { name, phone, email} = formState;
    let [error] = await to(addLead({
      funnel: getFunnel() || 'standard',
      name: name.value,
      phone: phone.value,
      email: email.value,
      eventName: 'seed2'
    }));
    setRegisterDisabled(false);
    console.log('### error', error);
    if (/g-a/i.test(location.pathname)) {
      history.push('/thankyou', { google: true });
    } else {
      history.push('/obrigado', { google: false });
    }
  }

  return(
    <form className={classes.root} noValidate autoComplete="off">
      <div className="form-layout">
        <TextField
          error={formState.name.error}
          helperText={formState.name.error && formState.name.helperText}
          label="nome"
          name="name"
          variant="outlined"
          onBlur={onBlur}
          onChange={handleChange}
          required
        />
        <TextField
          error={formState.email.error}
          helperText={formState.email.error && formState.email.helperText}
          label="email"
          name="email"
          type="email"
          variant="outlined"
          value={formState.email.value}
          onChange={handleChange}
          onBlur={onBlur}
          required
        />
        <TextField
          error={formState.phone.error}
          helperText={formState.phone.error && formState.phone.helperText}
          label="telefone"
          name="phone"
          type="tel"
          pattern="[0-9]{2}[0-9]{4}[0-9]{4}"
          value={formState.phone.value}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          variant="outlined"
          inputProps={{
            maxLength: 15
          }}
          required
        />
      </div>
      <FormControlLabel
        style={{ padding: '1rem'}}
        control={<Checkbox color="primary" checked={formState.agreement} onChange={handleChangeCheckbox} name="agreement" /> }
        label="De acordo com as Leis 12.965/2014 e 13.709/2018, que regulam o uso da Internet e o tratamento de dados pessoais no Brasil, autorizo Aécio Levy a enviar notificações por e-mail ou outros meios e concordo com sua Política de Privacidade."
        labelPlacement="top"
      />
      <Button
        variant="contained"
        color="primary"
        disabled={registerDisable}
        onClick={handleRegistration}
      >
        Registrar
      </Button>
    </form>
  );
}
